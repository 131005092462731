import React from 'react'
import filmImg from '../../images/portada-pelicula.webp'

const Main = () => {
    return (
       <section className="film-container">
            <div className="container-fluid">
                <div class="row">
                    <div className="col-12 col-lg-6 d-flex align-items-center">
                        <div className="text-content pe-0 pe-lg-4 pb-5 pb-lg-0">
                            <h1 className="title">"De Italia a España por Olivos"</h1>
                            <small className="text-op8">
                                <span className="me-4">2023</span>
                                <span className="me-4">13+</span>
                                <span>1h  8min</span>
                            </small>
                            <p className="fs-bold mt-4">Sinopsis:</p>
                            <p className="mt-2">"¿Cuando un amor del pasado se cruza de nuevo en tu camino? Nico, un reportero gráfico de 47 años que ha trabajado en diferentes partes del mundo, regresa a su ciudad natal, Buenos Aires, para establecerse. Por razones laborales, lo llevan de vuelta a Olivos, el barrio donde creció. Un día, mientras sale de una reunión, se encuentra por casualidad en la intersección de la calle Italia con Cari, su ex compañera del colegio y diseñadora de interiores, quien aún reside en la zona. No se han visto desde los años 90, cuando eran novios. A medida que Nico y Cari caminan juntos por el barrio, se embarcan en un viaje de recuerdos y emociones."</p>
                            <p className="d-flex flex-column my-4 my-lg-5 text-op8">
                                <span>Estreno: 03/11/2022</span>
                                <span>Protagonistas: Nicolás Pauls - Carina Cardoni</span>
                                <span>Dirección: Gustavo Giannini</span>
                            </p>
                            <p className="fs-bold mt-3 pt-3 mt-lg-4 pt-lg-4">No dejes pasar la oportunidad de enamorarte aún más de Vicente López a través de esta obra cinematográfica. ¿Qué estás esperando para verla?</p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                        <img src={filmImg} alt="De Italia a España por Olivos" className="film-img"/>          
                    </div>
                </div>
            </div>
       </section>
    )
}

export default Main