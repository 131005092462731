import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import toast from 'react-hot-toast'
import ReCAPTCHA from 'react-google-recaptcha'
import IconRightSvg from '../../images/svg/icon-right.svg'
import { connect } from 'react-redux'
import { sendFormFilm } from '../../redux/contactDucks'

const formPopUp = ({ dispatch }) => {

    const location = useLocation()

    // Timeout to show the component
    useEffect(() => {
        setTimeout(() => {
            document.getElementById("formPopUpBtn")?.click()
        }, 1000)
    }, [location])

    const { realEstate } = useStaticQuery(graphql`
        query {
        realEstate {
            keys{
                captcha
            }
        }
    }`);

    const [disabled, setDisabled] = useState(true)
    const [datos, setDatos] = useState({
        nombre: "",
        email: "",
        city: "",
    });

    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.value,
        });
    };

    const sendForm = (e) => {
        e.preventDefault();
        dispatch(sendFormFilm(datos))
        document.getElementById("closePopup").click();
        document.getElementById("formPelicula").reset();
        return toast.success(<span>¡Registro exitosos! Te mantendremos informado con todas las novedades de Bellagamba</span>) 
    }

    return (
        <>
            <div className="article">
                <button type="button" id="formPopUpBtn" data-bs-toggle="modal" data-bs-target="#formPopUp"></button>
                <div className="modal fade" id="formPopUp" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header position-relative">
                                <i className="icon-plus position-fixed" type="button" role="button" id="closePopup"
                                    data-bs-dismiss="modal" aria-label="Close"
                                        onClick={() => document.getElementById("closePopup").click()}></i>
                            </div>
                            <div className="modal-body">
                                <div className="form-container pb-4">
                                    <h1 className="text-center px-4 pt-3">¡Descubrí las novedades de BGB antes que nadie!</h1>
                                    <p className="text-center">Registrate para ser el primero en saberlo.</p>
                                    <form onSubmit={sendForm} id="formPelicula" className="content-form d-flex flex-column align-items-center mt-4 pt-2">
                                        <input type="text" name="nombre" onChange={handleInputChange} placeholder="Nombre y apellido*" className="mb-3" autocomplete="off" required/>
                                        <input type="email" name="email" onChange={handleInputChange} placeholder="E-mail*" className="mb-3" autocomplete="off" required/>
                                        <input type="text" name="city" onChange={handleInputChange} autocomplete="off" placeholder="Localidad" />
                                        <p className="required-text text-end mt-1">* Campos obligatorios</p>
                                        { realEstate?.keys?.captcha ? 
                                            <ReCAPTCHA
                                                sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                                                onChange ={() => setDisabled(false)}/> : null }
                                        <button disabled={disabled} type="submit" id="submit-info" className="btn d-flex submit justify-content-center align-items-center mt-4">
                                            ENVIAR <label className="mb-0" htmlFor="subit-info"><IconRightSvg className="icon ms-2 mb-0"/></label>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect()(formPopUp)