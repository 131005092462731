import React from "react";
import { useLocation } from "@reach/router";

const VideoSection = ({sectionId, videoSrc, videoTitle}) => {

    const location = useLocation();
    const isShulbius = location.pathname.includes('/shulbius');
    const isPelicula = location.pathname.includes('/pelicula');

    return (
        <section id={`${isShulbius ? "video-shulbius" : "video-section"}`} 
            className={`${isShulbius ? "video-shulbius" : "video-" + sectionId} ${isPelicula ? "pelicula-section" : ""}`}>
            <div className={(isPelicula ? "video-container" : "container-fluid")}>
                <div className="row">
                    { isShulbius ?
                        <div className="col-12">
                            <div className="img-wrapper d-flex justify-content-center align-items-center mt-5 pt-5">
                                <img className="logo" src="https://bellagamba.com/svg/shulbius.svg" alt="Shulbius"/>
                            </div>
                        </div> : '' }
                    { isPelicula ? 
                       <div className="col-12">
                          <div style={{padding:"56.25% 0 0 0", position:"relative"}} className="iframe-container">
                            <iframe src={videoSrc} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style={{position: "absolute", top:0, left:0, width:"100%"}} className="iframe-video" allowFullScreen title={videoTitle}></iframe>
                          </div>
                          <script src="https://player.vimeo.com/api/player.js"></script>
                       </div>
                    :  <div className="col-12 pt-5 pb-5 d-none d-lg-block mt-5 mb-4">
                          <div id="contenedor_video" className="m-0">
                            <div id="__player__"></div>
                              <iframe src={videoSrc} id="player" style={{ position: "absolute", border: "none", zIndex: 0}} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div> }
                </div>
            </div>
        </section>
    )
}

export default VideoSection;