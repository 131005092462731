import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import FormPopUp from "../components/Pelicula/formPopUp"
import VideoSection from "../components/videosection"
import Main from '../components/Pelicula/main'

const IndexPage = () => (
  <Layout>
    <Seo title="Película" description="Bellagamba - De Italia a España por Olivos" />
    <FormPopUp/>
    <VideoSection sectionId="pelicula" videoSrc="https://player.vimeo.com/video/875132756?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" videoTitle="de Italia a España por Olivos"/>
    <Main />
  </Layout>
)

export default IndexPage
